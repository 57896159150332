/**
 * Endpoint for all login related content
 */

import $ from 'jquery';
import './../styles/login.scss';
import ieMessage from '../components/alerts/ieMessage';

$(document).ready(function () {
    ieMessage();
});